import { classNames } from '../../../../shared/lib/classNames/classNames'
import { TendersCardViewNew } from '../TendersCardView/TendersCardViewNew'
import cls from './TendersNew.module.scss'

export const TendersNew = () => {
    return (
        <div className={classNames(cls.TendersNew, {}, [])}>
            <TendersCardViewNew />
        </div>
    )
}
