import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../../../app/providers/StoreProvider';
import { UserActions } from '../../../../entities/User';
import { USER_TOKEN_KEY } from '../../../../shared/lib/const/localstorage';


interface LoginByUsernameProps {
    email: string;
    password: string;
    callback: () => void
}

interface AuthInterface {
    userToken: string
}

export const loginByUsername = createAsyncThunk<
    AuthInterface,
    LoginByUsernameProps,
    ThunkConfig<string>
>('login/loginByUsername', async (authData, thunkApi) => {
    const { extra, dispatch, rejectWithValue } = thunkApi;

    console.log('aitasd')
    try {
        const response = await extra.api.post<AuthInterface>('/auth/login', { email: authData.email, password: authData.password });
        if (!response.data) {
            throw new Error();
        }
        localStorage.setItem(USER_TOKEN_KEY, response.data.userToken)
        dispatch(UserActions.setAuth(true));
        authData.callback()
        return response.data;
    } catch (e) {
        dispatch(UserActions.setAuth(false));
        return rejectWithValue('error');
    }
});
