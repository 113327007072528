import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../app/providers/StoreProvider'
import { EnumsData } from '../types/EnumsSchema'

export const fetchEnums = createAsyncThunk<
    EnumsData,
    void,
    ThunkConfig<string>
>('enums/fetchEnums', async (data, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi

    try {
        const response = await extra.api.get<EnumsData>('/auth/get_enums')
        if (!response.data) {
            throw new Error()
        }
        return response.data
    } catch (e: any) {
        return rejectWithValue('Ошибка при получении данныхё')
    }
})
