import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TenderFilterSchema } from '../types/TenderFilterSchema'

const initialState: TenderFilterSchema = {}

export const TenderFilterSlice = createSlice({
    name: 'TenderFilter',
    initialState,
    reducers: {
        reset: (state) => {
            state.searchKeyword = ''
            state.publishDateStart = undefined
            state.publishDateEnd = undefined
            state.deadlineStart = undefined
            state.deadlineEnd = undefined
            state.plannedAmountStart = undefined
            state.plannedAmountEnd = undefined
            state.organization = ''
            state.tenderTypeIds = undefined
            state.regionIds = undefined
            state.websiteIds = undefined
        },
        searchKeyword: (state, action: PayloadAction<string>) => {
            state.searchKeyword = action.payload
        },

        publishDateStart: (state, action: PayloadAction<string>) => {
            state.publishDateStart = action.payload
        },
        publishDateEnd: (state, action: PayloadAction<string>) => {
            state.publishDateEnd = action.payload
        },
        deadlineStart: (state, action: PayloadAction<string>) => {
            state.deadlineStart = action.payload
        },
        deadlineEnd: (state, action: PayloadAction<string>) => {
            state.deadlineEnd = action.payload
        },
        plannedAmountStart: (state, action: PayloadAction<number | null>) => {
            state.plannedAmountStart = action.payload
        },
        plannedAmountEnd: (state, action: PayloadAction<number | null>) => {
            state.plannedAmountEnd = action.payload
        },
        organization: (state, action: PayloadAction<string>) => {
            state.organization = action.payload
        },
        regionIds: (state, action: PayloadAction<number[]>) => {
            if (action.payload.length === 0) {
                return state.regionIds = undefined
            }
            state.regionIds = action.payload
        },
        websiteIds: (state, action: PayloadAction<number[]>) => {
            if (action.payload.length === 0) {
                return state.websiteIds = undefined
            }
            state.websiteIds = action.payload
        },
        tenderTypeIds: (state, action: PayloadAction<number[]>) => {
            if (action.payload.length === 0) {
                return state.tenderTypeIds = undefined
            }
            state.tenderTypeIds = action.payload
        }
    }
})

export const { actions: TenderFilterActions } = TenderFilterSlice
export const { reducer: TenderFilterReducer } = TenderFilterSlice
