
export enum AppRoutes {
    MAIN = 'main',

}

export enum ChildAppRoutes {
    //public
    MAIN_PAGE = 'MAIN_PAGE',
    REGISTER = 'REGISTER_PAGE',
    ABOUT = 'ABOUT_PAGE',
    CONTACTS = 'CONTACTS',
    USER_PROFILE = 'USER_PROFILE'
}

export const getRouteMainPage = () => '/'
export const getRouteUserRegisterPage = () => '/register'
export const getRouteAboutPage = () => '/about'
export const getRouteContactsPage = () => '/contacts'
export const getRouteUserProfilePage = () => '/profile'
export const getRouteAnalytics = () => 'https://analytics.vsetendery.kg'