import { 
    // FloatButton, 
    Tag 
} from 'antd'
// import { InfiniteScroll } from 'antd-mobile'
import { 
    useCallback, 
    // useMemo, useState 
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { Loader } from '../../../../shared/ui/Loader/Loader'
// import TenderCardSkeleton from '../../../../shared/ui/TenderCardSkeleton/TenderCardSkeleton'
// import { Text } from '../../../../shared/ui/Text'
import {
    getFoundTendersdata,
    // searchTendersInfinite
} from '../../../FoundTenders'
import { getIsSearchingStatus, IsSearchingActions } from '../../../IsSearching'
import { getTendersdata } from '../../model/selectors/getTendersdata'
import { 
    fetchTenders, 
    // fetchTendersInfinite 
} from '../../model/services'
import TenderCard from '../TenderCard/TenderCard'
import cls from './TendersCardView.module.scss'
import MyPagination from '../../../../widgets/MyPagnination/MyPagination'
import { searchTenders } from '../../../FoundTenders/model/services'
import { 
    // HStack, 
    VStack 
} from '../../../../shared/ui/Stack'

interface Props {}
export const TendersCardViewNew = (props: Props) => {
    const dispatch = useAppDispatch()
    const isSearching = useSelector(getIsSearchingStatus)
    const { t } = useTranslation()

    const { tenders, overall_tenders_count, isLoading } =
        useSelector(getTendersdata)
    const {
        foundtenders,
        overall_tenders_count_found,
        isLoading: searching,
        active_tenders,
        expired_tenders
    } = useSelector(getFoundTendersdata)

    const fetchTendersData = useCallback(
        (page: number, per_page: number) => {
            dispatch(fetchTenders({ pageNumber: page, perPage: per_page }))
        },
        [dispatch]
    )

    const fetchSearchTendersData = useCallback(
        (page: number, per_page: number) => {
            dispatch(IsSearchingActions.set(true))
            dispatch(
                searchTenders({
                    pageNumber: page,
                    perPage: per_page
                })
            )
        },
        [dispatch]
    )

    return (
        <div className={classNames(cls.list, {}, [])}>
            {Boolean(isSearching && !searching) && (
                <VStack gap="4">
                    <div>
                        {t('Найдено')}{' '}
                        <Tag>
                            {' '}
                            {overall_tenders_count_found.toLocaleString(
                                'ru-RU'
                            )}
                        </Tag>
                    </div>
                    <div>
                        {t('Активных тендеров')}{' '}
                        <Tag color="blue">
                            {active_tenders?.tenders_count.toLocaleString(
                                'ru-RU'
                            )}
                        </Tag>
                        <Tag color="green">
                            {active_tenders?.planned_amount_sum.toLocaleString(
                                'ru-RU'
                            )}{' '}
                            сом
                        </Tag>
                    </div>

                    <div>
                        {t('Неактивных тендеров')}{' '}
                        <Tag color="blue">
                            {expired_tenders?.tenders_count.toLocaleString(
                                'ru-RU'
                            )}
                        </Tag>
                        <Tag color="green">
                            {expired_tenders?.planned_amount_sum.toLocaleString(
                                'ru-RU'
                            )}{' '}
                            сом
                        </Tag>
                    </div>
                </VStack>
            )}

            {isLoading ||
                (searching ? (
                    <Loader />
                ) : (
                    <>
                        {isSearching
                            ? foundtenders?.map((tender, i) => (
                                  <TenderCard
                                      key={i}
                                      tenderItem={tender}
                                      index={i + 1}
                                  />
                              ))
                            : tenders?.map((tender, i) => (
                                  <TenderCard
                                      key={i}
                                      tenderItem={tender}
                                      index={i + 1}
                                  />
                              ))}
                    </>
                ))}

            <MyPagination
                text={
                    isSearching ? 'Найдено тендеров' : 'Всего тендеров на сайте'
                }
                onChange={
                    isSearching ? fetchSearchTendersData : fetchTendersData
                }
                total={
                    isSearching
                        ? overall_tenders_count_found
                        : overall_tenders_count
                }
            />
        </div>
    )
}
