import React, { useMemo } from 'react'
import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getEnumsdata } from '../../../../entities/Enums'
import { getOptions } from '../../model/helpers'
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { TenderFilterActions } from '../../model/slices/TenderFilterSlice'
import { getTenderFilterdata } from '../../model/selectors/getTenderFilterdata'
import cls from '../TenderFilter/TenderFilter.module.scss'

type DataKey = 'regions' | 'tenderTypes' | 'websites'

interface EnumsData {
    regions: any[]
    tenderTypes: any[]
    websites: any[]
}

interface TenderFilterData {
    regionIds: number[]
    tenderTypeIds: number[]
    websiteIds: number[]
}

interface CommonSelectorProps {
    dataKey: DataKey
}

const CommonSelector: React.FC<CommonSelectorProps> = ({ dataKey }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const { data: enumsData } = useSelector(getEnumsdata) as { data: EnumsData }
    const tenderFilterData = useSelector(
        getTenderFilterdata
    ) as TenderFilterData

    const actionCreators: Record<DataKey, (ids: number[]) => any> = {
        regions: TenderFilterActions.regionIds,
        tenderTypes: TenderFilterActions.tenderTypeIds,
        websites: TenderFilterActions.websiteIds
    }

    const placeholders: Record<DataKey, string> = {
        regions: 'Область',
        tenderTypes: 'Тип тендера',
        websites: 'Сайты'
    }

    const selectedIdsKeys: Record<DataKey, keyof TenderFilterData> = {
        regions: 'regionIds',
        tenderTypes: 'tenderTypeIds',
        websites: 'websiteIds'
    }

    const actionCreator = actionCreators[dataKey]
    const placeholder = placeholders[dataKey]
    const selectedIdsKey = selectedIdsKeys[dataKey]
    const selectedIds = tenderFilterData[selectedIdsKey]

    const options = useMemo(() => {
        const dataArray = enumsData?.[dataKey]
        if (!dataArray) {
            return []
        }
        return getOptions(dataArray)
    }, [enumsData, dataKey])

    const handleChangeSelect: SelectProps['onChange'] = (ids) => {
        const newIds = (ids as number[]) || []
        dispatch(actionCreator(newIds))
    }

    const filteredOptions = options.filter(
        (o) => !selectedIds?.includes(o.value)
    )

    const selectedTitles = options.filter((o) => selectedIds?.includes(o.value))

    return (
        <Select
            value={selectedTitles}
            notFoundContent=""
            size="large"
            rootClassName={cls.commontSelector}
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder={t(placeholder)}
            onChange={handleChangeSelect}
            options={filteredOptions}
        />
    )
}

export default CommonSelector
