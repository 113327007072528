import { Col, Grid, Row } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { BurgerMenu, BurgerMenuActions } from '../../entities/BurgerMenu'
import { DropDown } from '../../entities/BurgerMenu/ui/DropDown/DropDown'
import { getUserIsAuth, UserActions } from '../../entities/User'
import AuthButton from '../../features/AuthByUsername/ui/AuthButton/AuthButton'

import { UserProfileButton } from '../../features/UserProfile/ui/UserProfileButton/UserProfileButton'
import { classNames } from '../../shared/lib/classNames/classNames'
import {
    getRouteAboutPage,
    getRouteAnalytics,
    getRouteContactsPage,
    getRouteMainPage,
    getRouteUserRegisterPage
} from '../../shared/lib/const/router'
import { useAppDispatch } from '../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { AppLink } from '../../shared/ui/AppLink'
import { GetLogo } from '../../shared/ui/GetLogo/GetLogo'
import { LangSwitcher } from '../../shared/ui/LangSwitcher/LangSwitcher'
import { MainGridComponent } from '../../shared/ui/MainGridComponent/MainGridComponent'
import cls from './Navbar.module.scss'

const { useBreakpoint } = Grid

interface AuthLinksProps {
    isAuth: boolean
    logOut: () => void
    onRegisterClick: () => void
}
const AuthLinks = ({ isAuth, logOut, onRegisterClick }: AuthLinksProps) => {
    const { t } = useTranslation()
    return (
        <>
            {isAuth ? (
                <li onClick={logOut}>{t('Выйти')}</li>
            ) : (
                <>
                    <li>
                        <AuthButton variant="clear" />
                    </li>
                    <li onClick={onRegisterClick}>{t('Регистрация')}</li>
                </>
            )}
        </>
    )
}

export const Navbar = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const navigate = useNavigate()
    const isAuth = useSelector(getUserIsAuth)

    const s = useBreakpoint()
    const hide = (s.xs && !s.sm) || (!s.xs && s.sm && !s.lg)

    const links = [
        {
            name: t('Тендеры'),
            url: getRouteMainPage(),
            hidden: false
        },
        {
            name: t('Аналитика'),
            availableToAuthUsersOnly: false,
            hidden: false,
            url: getRouteAnalytics()
        },
        {
            name: t('О нас'),
            url: getRouteAboutPage(),
            hidden: false
        },
        {
            name: t('Контакты'),
            hidden: false,
            url: getRouteContactsPage()
        },
        // {
        //     name: t('Faq'),
        //     hidden: hide,
        //     url: ''
        // }
    ]

    const logOut = useCallback(() => {
        dispatch(UserActions.logOut())
    }, [dispatch])

    const onRegisterClick = useCallback(() => {
        dispatch(BurgerMenuActions.set(false))
        return navigate(getRouteUserRegisterPage())
    }, [navigate, dispatch])

    const showServiceAvailableToAuthUsersMessage = () => {
        alert(t('Данный сервис доступен только зарегистрированным пользователям'))
    }

    return (
        <div>
            <div className={classNames(cls.Navbar, {}, [])}>
                <MainGridComponent>
                    <Row justify={'space-between'}>
                        <Col className={cls.col}>
                            <AppLink to="/" style={{ display: 'flex' }}>
                                <GetLogo />
                                <div className={cls.logo_text}>
                                    {t('Все Тендеры')}
                                </div>
                            </AppLink>
                        </Col>
                        <Col className={cls.col}>
                            <ul className={cls.links}>
                                {links.map((link) => {
                                    if (link.hidden) {
                                        return null
                                    }
                                    else if (link?.availableToAuthUsersOnly && !isAuth){
                                        return (
                                            <li key={link.name} onClick={showServiceAvailableToAuthUsersMessage}>
                                                <AppLink to="/">
                                                    {link.name}
                                                </AppLink>
                                            </li>
                                        )
                                    }
                                    else {
                                        return (
                                            <li key={link.name}>
                                                <AppLink to={link.url}>
                                                    {link.name}
                                                </AppLink>
                                            </li>
                                        )
                                    }
                                })}
                            </ul>
                        </Col>
                        <Col>
                            {hide ? (
                                <BurgerMenu />
                            ) : (
                                <ul className={cls.links}>
                                    <AuthLinks
                                        isAuth={isAuth}
                                        logOut={logOut}
                                        onRegisterClick={onRegisterClick}
                                    />    
                                    <li>
                                        <LangSwitcher />
                                    </li>
                                    <li>{isAuth && <UserProfileButton />}</li>
                                </ul>
                            )}
                        </Col>
                    </Row>
                </MainGridComponent>
            </div>
            <DropDown />
        </div>
    )
}
