import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../app/providers/StoreProvider'
import { USER_TOKEN_KEY } from '../../../../shared/lib/const/localstorage'
import api from '../../../../shared/api/api'
import { AxiosResponse } from 'axios'

interface Response {
    searchTags: string[]
}

export const fetchUserTags = createAsyncThunk<
    Response,
    void,
    ThunkConfig<string>
>('tags/fetchUserTags', async (_, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi
    try {
        const response = await extra.api.post<Response>(
            '/tenders-api/get_user_search_tags',
            {
                userToken: localStorage.getItem(USER_TOKEN_KEY)
            }
        )
        if (!response.data) {
            throw new Error()
        }
        return response.data
    } catch (e: any) {
        return rejectWithValue('Ошибка при получении данных!')
    }
})

interface AddProps extends Response { }
export async function addUserTagsService(
    props: AddProps
): Promise<AxiosResponse<any>> {
    const getUserToken = () => {
        return localStorage.getItem(USER_TOKEN_KEY)
    }
    return api.post<any>(`/tenders-api/update_user_search_tags`, {
        userToken: getUserToken(),
        searchTags: props.searchTags
    })
}
