import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../app/providers/StoreProvider'
import { TenderFiltersProps } from '../../../../shared/lib/globalTypes/tenderSearch'
import { FoundTenders } from '../types/FoundTendersSchema'
import { getTenderFilterdata } from '../../../../features/TenderFilter'

interface Filters extends Omit<TenderFiltersProps, 'isSearching'> { }

interface FilterProps extends Filters {
    pageNumber: number
    perPage: number
}
function removeKeys<T extends object>(obj: T, keysToRemove: (keyof T)[]): Omit<T, keyof T> {
    const entries = Object.entries(obj)
        .filter(([key]) => !keysToRemove.includes(key as keyof T));
    return Object.fromEntries(entries) as Omit<T, keyof T>;
}


export const searchTenders = createAsyncThunk<
    FoundTenders,
    FilterProps,
    ThunkConfig<string>
>('foundTenders/searchTenders', async (data, thunkApi) => {
    const { extra, rejectWithValue, getState } = thunkApi

    const filter_data = getTenderFilterdata(getState())
    const newFilterData = removeKeys(filter_data, ['regionIds', 'tenderTypeIds', 'websiteIds']);



    try {
        const response = await extra.api.get<FoundTenders>(
            '/tenders-api/search',
            {
                params: {
                    ...newFilterData,
                    ...(filter_data.regionIds && {
                        regionIds: `[${filter_data.regionIds.toLocaleString()}]`
                    }),
                    ...(filter_data.tenderTypeIds && {
                        tenderTypeIds: `[${filter_data.tenderTypeIds.toLocaleString()}]`
                    }),
                    ...(filter_data.websiteIds && {
                        websiteIds: `[${filter_data.websiteIds.toLocaleString()}]`
                    }),
                    pageNumber: data.pageNumber - 1,
                    perPage: data.perPage
                }
            }
        )
        if (!response.data) {
            throw new Error()
        }
        return response.data
    } catch (e: any) {
        return rejectWithValue('error')
    }
})

export const searchTendersInfinite = createAsyncThunk<
    FoundTenders,
    FilterProps,
    ThunkConfig<string>
>('foundTenders/searchTendersInfinite', async (data, thunkApi) => {
    const { extra, rejectWithValue, getState } = thunkApi

    const filter_data = getTenderFilterdata(getState())
    try {
        const response = await extra.api.get<FoundTenders>(
            '/tenders-api/search',
            {
                params: {
                    ...filter_data,
                    pageNumber: data.pageNumber - 1,
                    perPage: data.perPage
                }
            }
        )
        if (!response.data) {
            throw new Error()
        }
        return response.data
    } catch (e: any) {
        return rejectWithValue('error')
    }
})
