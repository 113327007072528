import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EnumsSchema } from '../types/EnumsSchema'
import { fetchEnums } from '../services'

const initialState: EnumsSchema = {}

export const EnumsSlice = createSlice({
    name: 'Enums',
    initialState,
    reducers: {
        template: (state, action: PayloadAction<string>) => {}
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEnums.pending, (state) => {
                state.error = undefined
                state.isLoading = true
            })
            .addCase(fetchEnums.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchEnums.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    }
})

export const { actions: EnumsActions } = EnumsSlice
export const { reducer: EnumsReducer } = EnumsSlice
